import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../../../../Assets/Svg/arrow_forward_Gallery.svg";
import ImageSlider from "../../../../Components/Gallery_Images/ImageSlider";
import ThumbnailGallery from "../../../../Components/Gallery_Images/ThumbnailGallery";
import Image1 from "../../../../Product/School_Uniforms/Cardigans/C1.jpg";
import Image2 from "../../../../Product/School_Uniforms/Cardigans/C2.jpg";
import Image3 from "../../../../Product/School_Uniforms/Cardigans/C3.jpg";
import Image4 from "../../../../Product/School_Uniforms/Cardigans/C4.jpg";
import "../../Gallery_Product.css";

const Cardigans = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const images = [Image1, Image2, Image3, Image4];

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };
  const handleCasualClick = () => {
    navigate("/gallery", { state: { selectedCategory: "schooluniform" } });
  };
  return (
    <>
      <div className="Gallery_Product_Container">
        <div className="Gallery_Product_S1">
          <a href="/gallery">Gallery</a>
          <img src={ArrowLeft} alt="" />
          <h3 onClick={handleCasualClick} style={{ cursor: "pointer" }}>
            Educational
          </h3>
          <img src={ArrowLeft} alt="" />
          <h4>Graduation Gown</h4>
        </div>
        <div className="Gallery_Product_S2">
          <ImageSlider images={images} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
        </div>
        <div className="Gallery_Product_S3">
          <ThumbnailGallery images={images} onThumbnailClick={handleThumbnailClick} />
        </div>
      </div>
    </>
  );
};

export default Cardigans;
