import React from "react";
import "./SocialMediaIcons.css"; // CSS file for styling the component
import FaceBookIcon from "../../Assets/Svg/FacebookIcon.svg";
import LinkedInIcon from "../../Assets/Svg/LinkedInIcon.svg";
import InstagramIcon from "../../Assets/Svg/InstagramIcon.svg";

export default function SocialMediaIcons() {
  return (
    <div className="social-media-container">
      <a href="https://www.facebook.com/uniformarena" target="_blank" rel="noopener noreferrer">
        <img src={FaceBookIcon} alt="Facebook" />
      </a>
      {/* <a href="https://www.linkedin.com/company/uniformarena" target="_blank" rel="noopener noreferrer">
        <img src={LinkedInIcon} alt="Twitter" />
      </a> */}
      <a href="https://www.instagram.com/uniformarena/" target="_blank" rel="noopener noreferrer">
        <img src={InstagramIcon} alt="Instagram" />
      </a>
    </div>
  );
}
