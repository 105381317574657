import React, { useEffect } from "react";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { LanguageProvider } from "./LanguageContext";
import LanguageRoutes from "./LanguageRoutes";

export default function App() {
  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   document.addEventListener("contextmenu", handleContextMenu);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //   };
  // }, []);

  return (
    <LanguageProvider>
      <Router>
        <LanguageRoutes />
      </Router>
    </LanguageProvider>
  );
}
