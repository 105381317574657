import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../../../../Assets/Svg/arrow_forward_Gallery.svg";
import ImageSlider from "../../../../Components/Gallery_Images/ImageSlider";
import ThumbnailGallery from "../../../../Components/Gallery_Images/ThumbnailGallery";
import Image1 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs1.png";
import Image2 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs2.png";
import Image3 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs3.png";
import Image4 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs4.png";
import Image5 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs5.png";
import Image6 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs6.png";
import Image7 from "../../../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs7.png";

import "../../Gallery_Product.css";

const BlouseFormalCor = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };
  const handleCategoryClick = () => {
    navigate("/gallery", { state: { selectedCategory: "corporate" } }); // Pass 'casual' as state
  };
  return (
    <>
      <div className="Gallery_Product_Container">
        <div className="Gallery_Product_S1">
          <a href="/gallery">Gallery</a>
          <img src={ArrowLeft} alt="" />
          <h3 onClick={handleCategoryClick} style={{ cursor: "pointer" }}>
            Corporate
          </h3>
          <img src={ArrowLeft} alt="" />
          <h4>Blouse & formal shirts</h4>
        </div>
        <div className="Gallery_Product_S2">
          <ImageSlider images={images} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
        </div>
        <div className="Gallery_Product_S3">
          <ThumbnailGallery images={images} onThumbnailClick={handleThumbnailClick} />
        </div>
      </div>
    </>
  );
};

export default BlouseFormalCor;
