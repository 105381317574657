import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import GalleryBgStatic from "../../Assets/Svg/Gallery_Back_Stitc.svg";
import GalleryS3Image1 from "../../Assets/Svg/Gallery_S3_Image1.svg";
import GalleryWaistCoat from "../../Product/School_Uniforms/waistcoat/WC1.jpg";
import GalleryVestsSchool from "../../Product/School_Uniforms/Vests/V1.jpg";
import GalleryTrousersShorts from "../../Product/School_Uniforms/Trousers-Shorts/TS2.jpg";
import GallerySportsWear from "../../Product/School_Uniforms/Sports-Wear/SW1.jpg";
import GallerySkirtsSkorts from "../../Product/School_Uniforms/Skirts-Skorts/S1.jpg";
import GalleryShirtsSchool from "../../Product/School_Uniforms/Shirts/BS 1.jpg";
import GalleryPolosTShirts from "../../Product/School_Uniforms/Polos_T-Shirts/PT1.jpg";
import GalleryPinaFores from "../../Product/School_Uniforms/Pinafores/PF1.jpg";
import GalleryJumperSchool from "../../Product/School_Uniforms/Jumper/J1.jpg";
import GalleryGraduationGown from "../../Product/School_Uniforms/Graduation-Gown/GG1.jpg";
import GalleryCardigansSchool from "../../Product/School_Uniforms/Cardigans/C1.jpg";
import GalleryBlazersSchool from "../../Product/School_Uniforms/Blazers/B1.jpg";
import GallleryHoodySweatShirt from "../../Product/Casual/Hoody_Sweatshirt/HSS1.jpg";
import GalleryJacketsVestCas from "../../Product/Casual/Jackets_Vest_Cas/J&V1.jpg";
import GalleryPoloCasual from "../../Product/Casual/Polo_Cas/p1.jpg";
import GalleryCapCasual from "../../Product/Casual/Cap_Cas/Cp1.jpg";
import GalleryVisibilitySuit from "../../Product/Industrial/Visibility_Suit/VSBS 1.jpg";
import GalleryVisibilityShirt from "../../Product/Industrial/Visibility_Shirt/VS1.jpg";
import GalleryVisibilityJacket from "../../Product/Industrial/Visibility_Jacket/VJ1.jpg";
import GalleryVisibilityHiVisBib from "../../Product/Industrial/Visibility_Hi_Vis_Bib/VHB 1.jpg";
import GalleryVisibilityCoverall from "../../Product/Industrial/Visibility_Coverall/VC 1.png";
import GalleryIndustrialVest from "../../Product/Industrial/Vest/IV1.jpg";
import GalleryFireRetardantCoverall from "../../Product/Industrial/Fire_Retardant_coverall/FRC 1.png";
import GalleryCoverall from "../../Product/Industrial/Coverall/CVA 1.png";
import GalleryAprons from "../../Product/Hospitality/Aprons/APR1.png";
import GalleryChefCoat from "../../Product/Hospitality/Chef_Coat/CC1.jpg";
import GalleryChefSuit from "../../Product/Hospitality/Chef_Suit/CS1.jpg";
import GalleryChefHat from "../../Product/Hospitality/Chef_Hat/CH2.jpg";
import GalleryOutfitSport from "../../Product/Sportswear/Outfit-Sport/Ot1.jpg";
import GalleryTopsBottomsSport from "../../Product/Sportswear/Tops&Bottoms-Sport/tb1.jpg";
import GallerySuitsVestsCor from "../../Product/Corporate/Suits&Vests-Cor/St&Vs1.png";
import GallerySecurityCor from "../../Product/Corporate/Security-Cor/Sec1.png";
import GalleryBlouseFormalCor from "../../Product/Corporate/Blouse&Formal-Shirts-Cor/B&Fs1.png";
import { ReactComponent as GalleryIconAll } from "../../Assets/Svg/GalleryIconAll.svg";
import { ReactComponent as ScoolUniform } from "../../Assets/Svg/HomeS5P2Img2.svg";
import { ReactComponent as CorporateUniform } from "../../Assets/Svg/HomeS5P2Img3.svg";
import { ReactComponent as IndustrialUniform } from "../../Assets/Svg/HomeS5P2Img4.svg";
import { ReactComponent as HospitalityUniform } from "../../Assets/Svg/HomeS5P2Img7.svg";
import { ReactComponent as MedicalUniform } from "../../Assets/Svg/HomeS5P2Img10.svg";
import { ReactComponent as CasualUniform } from "../../Assets/Svg/HomeS5P2Img11.svg";
import { ReactComponent as SportsUniform } from "../../Assets/Svg/HomeS5P2Img12.svg";
import "./GalleryS3.css";

const uniformCategories = [
  { id: "All", name: "ALL", icon: <GalleryIconAll /> },
  { id: "schooluniform", name: "EDUCATIONAL", icon: <ScoolUniform /> },
  // { id: "medical", name: "MEDICAL", icon: <MedicalUniform /> },
  { id: "casual", name: "CASUAL", icon: <CasualUniform /> },
  {
    id: "industrial",
    name: "INDUSTRIAL",
    icon: <IndustrialUniform />,
  },
  {
    id: "hospitality",
    name: "HOSPITALITY ",
    icon: <HospitalityUniform />,
  },
  { id: "sports", name: "SPORTS WEAR ", icon: <SportsUniform /> },
  { id: "corporate", name: "CORPORATE ", icon: <CorporateUniform /> },
];

const uniformItems = {
  schooluniform: [
    {
      id: "WaistCoat School",
      name: "WaistCoat",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryWaistCoat,
      link: "/Waistcoat",
    },
    {
      id: "Vests School",
      name: "Vests",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVestsSchool,
      link: "/Vests-Schools",
    },
    {
      id: "Trousers Shorts School",
      name: "Trousers & Shorts",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryTrousersShorts,
      link: "/Trousers-Shorts",
    },
    {
      id: "Sport Swear School",
      name: "Sport Swear",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySportsWear,
      link: "/Sports-Wear",
    },
    {
      id: "Skirts Skorts School",
      name: "Skirts & Skorts",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySkirtsSkorts,
      link: "/Skirts-Skorts",
    },
    {
      id: "Shirts School",
      name: "Shirts",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryShirtsSchool,
      link: "/Shirts-School",
    },
    {
      id: "Polos T-Shirts School",
      name: "Polos & T-Shirts",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryPolosTShirts,
      link: "/Polos-TShirts",
    },
    {
      id: "Pina Fores School",
      name: "Pinafores",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryPinaFores,
      link: "/PinaFores",
    },
    {
      id: "Jumper School",
      name: "Jumper",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryJumperSchool,
      link: "/Jumper-School",
    },
    {
      id: "Graduation Gown School",
      name: "Graduation Gown",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryGraduationGown,
      link: "/Graduation-Gown",
    },
    {
      id: "Cardigans School",
      name: "Cardigans",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryCardigansSchool,
      link: "/Cardigans-School",
    },
    {
      id: "Blazers School",
      name: "Blazers",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryBlazersSchool,
      link: "/Blazers-School",
    },
  ],
  medical: [
    // {
    //   id: "coat medical",
    //   name: "Coat",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
  ],
  casual: [
    {
      id: "hoodies casual",
      name: "Hoody Sweat Shirt",
      backgroundImage: GalleryBgStatic,
      mainImage: GallleryHoodySweatShirt,
      link: "/HoodySweatShirt",
    },
    {
      id: "JacketsVestCas",
      name: "Jackets & Vest",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryJacketsVestCas,
      link: "/JacketsVestCas",
    },
    {
      id: "Polo casual",
      name: "Polo",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryPoloCasual,
      link: "/PoloCasual",
    },
    {
      id: "Cap casual",
      name: "Cap",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryCapCasual,
      link: "/CapCasual",
    },
  ],
  industrial: [
    {
      id: "VisibilitySuit industrial",
      name: "Visibility Suit",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilitySuit,
      link: "/VisibilitySuit",
    },
    {
      id: "VisibilityShirt industrial",
      name: "Visibility Shirt",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilityShirt,
      link: "/VisibilityShirt",
    },
    {
      id: "Visibility_Jacket industrial",
      name: "Visibility Jacket",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilityJacket,
      link: "/VisibilityJacket",
    },
    {
      id: "VisibilityHiVisBib industrial",
      name: "Visibility Hi Vis Bib",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilityHiVisBib,
      link: "/VisibilityHiVisBib",
    },
    {
      id: "VisibilityCoverall industrial",
      name: "Visibility Coverall",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilityCoverall,
      link: "/VisibilityCoverall",
    },
    {
      id: "IndustrialVest industrial",
      name: "Industrial Vest",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryIndustrialVest,
      link: "/IndustrialVest",
    },
    {
      id: "FireRetardantCoverall industrial",
      name: "Fire Retardant Coverall",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryFireRetardantCoverall,
      link: "/FireRetardantCoverall",
    },
    {
      id: "Coverall industrial",
      name: "Coverall",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryCoverall,
      link: "/Coverall",
    },
  ],
  hospitality: [
    {
      id: "aprons hospitality",
      name: "Aprons",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryAprons,
      link: "/Aprons",
    },
    // {
    //   id: "chefCap hospitality",
    //   name: "Chef Cap",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    {
      id: "chefCoat hospitality",
      name: "Chef Coat",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryChefCoat,
      link: "/ChefCoat",
    },
    {
      id: "chefSuit hospitality",
      name: "Chef Suit",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryChefSuit,
      link: "/ChefSuit",
    },
    {
      id: "chefHat hospitality",
      name: "Chef Hat",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryChefHat,
      link: "/ChefHat",
    },
    // {
    //   id: "waiters hospitality",
    //   name: "Waiters",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "housekeeping hospitality",
    //   name: "Housekeeping",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "bellhop hospitality",
    //   name: "Bellhop",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "frontDesk hospitality",
    //   name: "Front Desk",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "salons&Spas hospitality",
    //   name: "Salons & Spas",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "cabinCrew hospitality",
    //   name: "Cabin Crew",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
  ],
  sports: [
    {
      id: "outfit sports",
      name: "Outfit",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryOutfitSport,
      link: "/OutfitSport",
    },
    {
      id: "Tops-Bottoms-Sports",
      name: "Tops & Bottoms",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryTopsBottomsSport,
      link: "/TopsBottomsSport",
    },
  ],
  corporate: [
    {
      id: "Suits & Vests corporate",
      name: "Suits & Vests",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySuitsVestsCor,
      link: "/SuitsVestsCor",
    },
    {
      id: "Security corporate",
      name: "Security",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySecurityCor,
      link: "/SecurityCor",
    },
    {
      id: "Blouse & formal corporate",
      name: "Blouse & formal shirts",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryBlouseFormalCor,
      link: "/BlouseFormalCor",
    },
  ],
};

export default function GalleryS3Com() {
  const location = useLocation();

  // Determine the default category based on screen size and passed state
  const defaultCategory = window.innerWidth < 768 ? "schooluniform" : "All";
  const passedCategory = location.state?.selectedCategory || defaultCategory;

  const [selectedCategory, setSelectedCategory] = useState(passedCategory);

  // Use useEffect to reset the category when there is no state passed
  useEffect(() => {
    if (!location.state) {
      setSelectedCategory(defaultCategory); // Set based on screen size
    }
  }, [location.state, defaultCategory]);

  //
  //
  //
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  // Function to get items to display based on selected category
  const getItemsToDisplay = () => {
    if (selectedCategory === "All") {
      return Object.values(uniformItems).flat(); // This already fetches all categories
    } else {
      return uniformItems[selectedCategory] || [];
    }
  };

  const CategoryButton = ({ category, isSelected, onClick }) => (
    <button className={`category-button ${isSelected ? "active" : ""}`} onClick={() => onClick(category.id)}>
      {category.icon && <span className="icon">{category.icon}</span>}
      {category.name}
    </button>
  );

  return (
    <div className="GalleryS3">
      <div className="categories">
        {uniformCategories.map((category) => (
          <CategoryButton key={category.id} category={category} isSelected={selectedCategory === category.id} onClick={handleCategoryClick} />
        ))}
      </div>
      <div className="uniform-items">
        {getItemsToDisplay().map((item) => (
          <a key={item.id} href={item.link} className="uniform-item-link">
            <div className="uniform-item">
              <div className="uniform-item-background" style={{ backgroundImage: `url(${item.backgroundImage})` }}>
                <div className="uniform-item-name">{item.name}</div>
                <a href={item.link} className="view-details-button">
                  Click To View
                </a>
              </div>
              <img
                src={item.mainImage}
                alt={item.name}
                className="uniform-item-main-image"
                loading="lazy"
                onError={(e) => {
                  e.target.src = "path-to-fallback-image.jpg";
                }}
              />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
