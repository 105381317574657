import React, { useState, useEffect } from "react";
// Import unique icons for each item
import { ReactComponent as Icon1 } from "../../Svg/OFFICE_UNIFORMS.svg";
import { ReactComponent as Icon2 } from "../../Svg/SCHOOL_UNIFORMS.svg";
import { ReactComponent as Icon3 } from "../../Svg/CORPORATE_UNIFORMS.svg";
import { ReactComponent as Icon4 } from "../../Svg/INDUSTRIAL_UNIFORMS.svg";
import { ReactComponent as Icon5 } from "../../Svg/FACTORY_UNIFORM.svg";
import { ReactComponent as Icon6 } from "../../Svg/MAINTENANCE_UNIFORM.svg";
import { ReactComponent as Icon7 } from "../../Svg/CONSTRUCTION_WORKER_UNIFORM.svg";
import { ReactComponent as Icon8 } from "../../Svg/SECURITY_UNIFORMS.svg";
import { ReactComponent as Icon9 } from "../../Svg/CLEANING_UNIFORM.svg";
import { ReactComponent as Icon10 } from "../../Svg/MEDICAL_UNIFORMS.svg";
import { ReactComponent as Icon11 } from "../../Svg/T-SHIRT_PRINTING.svg";
import { ReactComponent as Icon12 } from "../../Svg/CUSTOM_EMBROIDERY.svg";
import { ReactComponent as Icon13 } from "../../Svg/HOTEL_STAFF_UNIFORM.svg";

import "./choice.css";

const ChoiceButton = ({ choice, isSelected, onClick }) => {
  const SVGComponent = choice.Icon;

  return (
    <button className={`choice-button ${isSelected ? "selected" : ""}`} onClick={onClick} type="button" name="user_choices">
      <SVGComponent className={`choice-svg ${isSelected ? "active" : ""}`} />
      {choice.label}
    </button>
  );
};

const Choices = ({ onChoicesChange }) => {
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const choices = isMobile
    ? [
        // Array for mobile screen (<768px)
        { id: 1, label: "EDUCATIONAL", Icon: Icon1 },
        { id: 2, label: "MEDICAL ", Icon: Icon2 },
        { id: 3, label: "CASUAL ", Icon: Icon3 },
        { id: 4, label: "INDUSTRIAL", Icon: Icon4 },
        { id: 5, label: "HOSPITALITY", Icon: Icon5 },
        { id: 6, label: "SPORTS WEAR", Icon: Icon6 },
        { id: 7, label: "CORPORATE", Icon: Icon7 },
      ]
    : [
        // Array for desktop screen (>=768px)
        { id: 1, label: "EDUCATIONAL ", Icon: Icon1 },
        { id: 2, label: "HOSPITALITY ", Icon: Icon2 },
        { id: 3, label: "SPORTS WEAR ", Icon: Icon3 },
        { id: 4, label: "INDUSTRIAL ", Icon: Icon4 },
        { id: 5, label: "MEDICAL ", Icon: Icon5 },
        { id: 6, label: "CASUAL ", Icon: Icon6 },
        { id: 7, label: "CORPORATE ", Icon: Icon7 },
      ];
  // const choices = [
  //   {
  //     id: 1,
  //     label: "OFFICE UNIFORMS",
  //     Icon: Icon1,
  //   },
  //   {
  //     id: 2,
  //     label: "SCHOOL UNIFORMS",
  //     Icon: Icon2,
  //   },
  //   {
  //     id: 3,
  //     label: "CORPORATE UNIFORMS",
  //     Icon: Icon3,
  //   },
  //   {
  //     id: 4,
  //     label: "INDUSTRIAL UNIFORMS",
  //     Icon: Icon4,
  //   },
  //   { id: 5, label: "FACTORY UNIFORM", Icon: Icon5 },
  //   { id: 6, label: "MAINTENANCE UNIFORM", Icon: Icon6 },
  //   { id: 7, label: "CONSTRUCTION WORKER UNIFORM", Icon: Icon7 },
  //   { id: 8, label: "SECURITY UNIFORMS", Icon: Icon8 },
  //   { id: 9, label: "CLEANING UNIFORM", Icon: Icon9 },
  //   { id: 10, label: "MEDICAL UNIFORMS", Icon: Icon10 },
  //   { id: 11, label: "T-SHIRT PRINTING", Icon: Icon11 },
  //   { id: 12, label: "CUSTOM EMBROIDERY", Icon: Icon12 },
  //   { id: 13, label: " HOTEL STAFF UNIFORM", Icon: Icon13 },
  // ];

  const toggleChoice = (choiceId) => {
    const choice = choices.find((c) => c.id === choiceId);

    const isCurrentlySelected = selectedChoices.some((c) => c.id === choiceId);

    const newSelectedChoices = isCurrentlySelected ? selectedChoices.filter((c) => c.id !== choiceId) : [...selectedChoices, choice];

    setSelectedChoices(newSelectedChoices);

    onChoicesChange(newSelectedChoices);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="choices-container">
      {choices.map((choice) => (
        <React.Fragment key={choice.id}>
          <ChoiceButton choice={choice} isSelected={selectedChoices.some((c) => c.id === choice.id)} onClick={() => toggleChoice(choice.id)} />
          <input type="hidden" name="user_choices" value={selectedChoices.some((c) => c.id === choice.id) ? choice.label : ""} style={{ display: "none" }} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Choices;
