import React from "react";
import MailIcon from "../../Assets/Svg/mail.svg";
import "./ButtonContact.css";

export default function ButtonContact() {
  return (
    <a href="/contact-us" className="Contact_Text">
      <button className="Contact_Button">
        Contact Us
        <img src={MailIcon} alt="" />
      </button>
    </a>
  );
}
