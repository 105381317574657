import React from "react";
import "./Footer.css";
import FooterFrame from "../../Assets/Svg/FooterFrame.svg";
import FooterLogo from "../../Assets/Svg/FooterLogo.svg";
import FacebookIcon from "../../Assets/Svg/FaceIconWhite.svg";
import LinkedInIcon from "../../Assets/Svg/LinkedIconWhite.svg";
import InstagramIcon from "../../Assets/Svg/InstaIconWhite.svg";

export default function ArabicFooter() {
  return (
    <div className="Footer_Container">
      {/* footer part one */}
      <div className="Footer_P1">
        <img src={FooterLogo} alt="FooterLogo" className="FooterLogo" />

        <h3 className="Footer_P1_H3">! اهلاً بك فى يونيفورم ارينا , تواصل معنا اليوم</h3>
      </div>
      {/* footer part two */}
      <div className="Footer_P2">
        <a href="/contact-us/ar">اتصل بنا</a>
        <a href="/gallery/ar">المعرض</a>
        <a href="/about-us/ar">من نحن</a>
        <a href="/">الصفحة الرئيسية</a>
      </div>
      {/* footer part three */}
      <div className="Footer_P3">
        <a href="https://www.facebook.com/uniformarena" target="_blank" rel="noopener noreferrer">
          <img src={FacebookIcon} alt="FacebookIcon" className="FacebookIconFooter" />
        </a>
        {/* <a href="https://www.linkedin.com/company/uniformarena" target="_blank" rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="LinkedInIcon" />
        </a> */}
        <a href="https://www.instagram.com/uniformarena/" target="_blank" rel="noopener noreferrer">
          <img src={InstagramIcon} alt="InstagramIcon" />
        </a>
      </div>
      {/* footer part four */}
      <div>
        <h3 className="Footer_P4_H3">© يحتفظ يونيفورم ارينا بكل حقوق النشر 2024</h3>
      </div>
    </div>
  );
}
