import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./HeaderMob.css";
import Logo from "../../Assets/Svg/Logo.svg";
import SocialMediaIcons from "../StickySocialMedia/SocialMediaIcons";
import HeaderMobIcon from "../../Assets/Svg/HeaderMobIcon.svg";
import HeaderMobClose from "../../Assets/Svg/HeaderMobClose.svg";

export default function EnglishHeaderMobile() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.endsWith("/ar");
    const basePath = isArabic ? currentPath.slice(0, -3) : currentPath;
    const newPath = isArabic ? basePath : `${basePath}${basePath.endsWith("/") ? "ar" : "/ar"}`;
    navigate(newPath, { replace: true });
    setShowDropdown(false); // Close dropdown after language change
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setShowDropdown(false);
  };

  return (
    <>
      <div className="Header_Main_Mob">
        <div className="Header_Main_Container">
          <div className="Header_Container">
            <SocialMediaIcons />
            <div className="Logo">
              <a href="/">
                <img src={Logo} alt="Logo" className="Header_Logo_Mob" />
              </a>
            </div>
            <div className="OpenIcon_Mob">
              <button className="OpenIcon_Mob_Button" onClick={toggleDropdown}>
                <img src={showDropdown ? HeaderMobClose : HeaderMobIcon} alt="MenuIcon" className="MenuIcon_Mob" />
              </button>
            </div>
          </div>
        </div>
        {showDropdown && (
          <div className="DropdownMenu">
            <ul>
              <li onClick={() => handleNavigation("/")}>Home</li>
              <li onClick={() => handleNavigation("/about-us")}>About Us</li>
              <li onClick={() => handleNavigation("/gallery")}>Gallery</li>
              <li onClick={() => handleNavigation("/contact-us")}>Contact Us</li>
              {/* <li onClick={handleLanguageChange}>Ar</li> */}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
