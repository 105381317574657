import React, { useState, useEffect } from "react";
import HomeContent from "./Home";
import Header from "../../Components/Header/Index";
import Footer from "../../Components/Footer/Index";

export default function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 475);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 475);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <HomeContent />
    </>
  );
}
