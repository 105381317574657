import React from "react";
import MailIcon from "../../Assets/Svg/mail.svg";
import "./ButtonContact.css";

export default function ButtonContactAr() {
  return (
    <button className="Contact_Button">
      <a href="/contact-us/ar" className="Contact_Text">
        اتصل بنا
      </a>
      <img src={MailIcon} alt="Mail Icon" />
    </button>
  );
}
