import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import "./Header.css";
import Logo from "../../Assets/Svg/Logo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SocialMediaIcons from "../StickySocialMedia/SocialMediaIcons";
import ButtonContactAr from "../ButtonContact/ButtonContactAr";

export default function ArabicHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Changed to useNavigate

  const handleLanguageSwitch = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.includes("/ar"); // Check if current language is Arabic

    if (isArabic) {
      const basePath = currentPath.slice(0, -3); // Remove "/ar" from the current path

      // If the basePath is empty, it means we are at the root in Arabic version
      const newPath = basePath === "" ? "/" : basePath;

      navigate(newPath, { replace: true }); // Navigate without pushing a new history entry
    }
  };

  return (
    <>
      <div className="Header_Main_C">
        <div className="Header_Main_Container">
          <div className="Header_Container">
            <SocialMediaIcons />
            <div className="Language">
              <div>
                <ButtonContactAr />
              </div>
              <div
                className="Header_Languages"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <div className="Header_Languages_Main">
                  <h4 className="Language_Dropdown_Text">العربية</h4>
                  <KeyboardArrowDownIcon style={{ color: "#b84031" }} />
                </div>
                {showDropdown && (
                  <div className="Language_Dropdown">
                    <button
                      className="Language_Dropdown_Text"
                      onClick={() => handleLanguageSwitch("en")}
                    >
                      الإنجليزية
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="Menu">
              <a href="/gallery/ar" className="Menu_Text_Ar">
                المعرض
              </a>
              <a href="/about-us/ar" className="Menu_Text_Ar">
                من نحن
              </a>
              <a href="/ar" className="Menu_Text_Ar">
                الصفحة الرئيسية
              </a>
            </div>
            <div className="Logo">
              <a href="/ar">
                <img src={Logo} alt="Logo" className="Header_Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
