import React, { useRef } from "react";
import "./ThumbnailGallery.css";

const ThumbnailGallery = ({ images, onThumbnailClick }) => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      const imageWidth = scrollRef.current.children[0].offsetWidth + 10;
      scrollRef.current.scrollLeft -= imageWidth;
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      const imageWidth = scrollRef.current.children[0].offsetWidth + 10;
      scrollRef.current.scrollLeft += imageWidth;
    }
  };

  const galleryClass = images.length > 5 ? "flex-start" : "center";

  return (
    <div className="thumbnail-gallery-container">
      <div className="thumbnail-gallery-left-gradient" onClick={scrollLeft}>
        <i className="fas fa-chevron-left arrow"></i>
      </div>
      <div className={`thumbnail-gallery ${galleryClass}`} ref={scrollRef}>
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Thumbnail ${index + 1}`} onClick={() => onThumbnailClick(index)} />
        ))}
      </div>
      <div className="thumbnail-gallery-right-gradient" onClick={scrollRight}>
        <i className="fas fa-chevron-right arrow"></i>
      </div>
    </div>
  );
};

export default ThumbnailGallery;
