import React, { useEffect, useState } from "react";
import "./About.css";
import AboutS1P1BIcon from "../../Assets/Svg/AboutS1P1BIcon.svg";
import AboutS2P2L from "../../Assets/Images/AboutS2P2L.png";
import AboutS2P2R from "../../Assets/Images/AboutS2P2R.png";
import ButtonContact from "../../Components/ButtonContact/ButtonContact";
import CompanyProfile from "../../Assets/PDF/Company_Profile.pdf";

export default function AboutContent() {
  return (
    <>
      <div className="About_Container">
        <AboutS1 />
        <AboutS2 />
        <AboutS3 />
      </div>
    </>
  );
}
function AboutS1() {
  return (
    <div className="AboutS1">
      <div className="AboutS1P1">
        <h5>About us</h5>
        <h1>The Confidence to Perform, Starts with How You Look.</h1>
        <a href={CompanyProfile} download="Company_Profile.pdf" className="HomeS4_P1_A">
          <button className="AboutS4_P1_B">
            Download Our Company Profile
            <img src={AboutS1P1BIcon} alt="" />
          </button>
        </a>
      </div>
      <div className="AboutS1P2">
        <div>
          <span>Uniform Arena</span> is a national uniform brand of Al Awadi Group which was established in 2016 in Qatar. Today we have successfully launched our Qatari uniform line using the best machinery available in the market with cutting-edge technology to maintain the highest quality standards in order to
          achieve customer satisfaction. We utilize our extensive knowledge of uniform-making helping schools dress their students with bespoke uniform manufacturing & design. By providing customised and personalised services, we always ensure our top quality services by placing the customers’ needs first and
          prioritising their satisfaction.
        </div>
      </div>
    </div>
  );
}

function AboutS2() {
  return (
    <>
      <div className="AboutS2">
        <div className="AboutS2P1">
          <h1>Uniforms that Make a Difference.</h1>
        </div>
        <div className="AboutS2P2">
          <div className="AboutS2P2B">
            <h1>our mission</h1>
            <p>Manufacturing products comparable to international standards, through better quality, latest technology and continuous innovation.</p>
            <img src={AboutS2P2L} alt="" className="AboutS2P2Img" />
          </div>
          <div className="AboutS2P2B">
            <h1>our VISION</h1>
            <p>Maintaining a long term leadership position in the textile market segments in which it competes. This will be achieved through operating efficiency, continued dedication to customer care, cost-effectiveness and innovation.</p>
            <img src={AboutS2P2R} alt="" className="AboutS2P2Img" />
          </div>
        </div>
      </div>
    </>
  );
}
function AboutS3() {
  return (
    <>
      <div className="HomeS9">
        <h3>Have questions or a unique uniform vision?</h3>
        <h1>Email us today to discuss how we can bring it to life!</h1>
        <a href="/contact-us">
          <ButtonContact />
        </a>
      </div>
    </>
  );
}
