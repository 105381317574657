import React, { useState, useEffect } from "react";
import "./Home.css";
import ButtonContact from "../../Components/ButtonContact/ButtonContact";
import HomeS1Img from "../../Assets/Images/HomeS1.png";
import HomeS2ImgLoop from "../../Components/Home/HomeS2ImgesLoop/HomeS2ImagesLoop";
import HomeS3P2C1L1 from "../../Assets/Svg/Home_S3_P2_C1_L1.svg";
import HomeS3P2C1L2 from "../../Assets/Svg/Home_S3_P2_C1_L2.svg";
import HomeS3P2C1L3 from "../../Assets/Svg/Home_S3_P2_C1_L3.svg";
import HomeS3P2C1L4 from "../../Assets/Svg/Home_S3_P2_C1_L4.svg";
import HomeS3P2C from "../../Components/Home/HomeS3P2C/HomeS3P2C";
import HomeS4P2Img1 from "../../Assets/Images/HomeS4_P2_Img1.png";
import HomeS4P2Img2 from "../../Assets/Images/HomeS4_P2_Img2.png";
import HomeS4P2Img3 from "../../Assets/Images/HomeS4_P2_Img3.png";
import HomeS5P2 from "../../Components/Home/HomeS5P2/HomeS5P2";
import HomeS6ImgR from "../../Assets/Svg/HomeS6ImgL.svg";
import HomeS6ImgL from "../../Assets/Svg/HomeS6P1B-B.svg";
import HomeS7P1Img from "../../Assets/Svg/HomeS7P1Img.svg";
import HomeS7P2ImgB from "../../Assets/Svg/HomeS7P2B-B.svg";
import TestimonialHomeS8 from "../../Components/TestimonialHome/TestimonialHome";

export default function HomeContent() {
  return (
    <>
      <div className="Home_Container">
        <HomeS1 />
        <HomeS2 />
        <HomeS3 />
        <HomeS4 />
        <HomeS5 />
        <HomeS6 />
        <HomeS7 />
        <HomeS8 />
        <HomeS9 />
      </div>
    </>
  );
}
//
function HomeS1() {
  return (
    <>
      <div className="HomeS1">
        <div className="HomeS1_Container">
          <div className="HomeS1_P1">
            <h3 className="HomeS1_P1_H3">Creating premium uniforms</h3>
            <h1 className="HomeS1_P1_H1">Dress to Impress. Empower your team with confidence.</h1>
            <img src={HomeS1Img} alt="" className="HomeS1ImgMob" />
            <p className="HomeS1_P1_P">Engage with your passionate team for professional designs to leave a lasting impression.</p>
          </div>
          <div>
            <ButtonContact />
          </div>
        </div>
        <div className="HomeS1_P2">
          <img src={HomeS1Img} alt="HomeS1Img" className="HomeS1Img" />
        </div>
      </div>
    </>
  );
}
//
function HomeS2() {
  return (
    <>
      <div className="HomeS2">
        <h3 className="HomeS2_P1_H3">Over then +20 success story</h3>
        <h1 className="HomeS2_P1_H1">Our trusted Clients</h1>
        <HomeS2ImgLoop />
      </div>
    </>
  );
}
//
function HomeS3() {
  return (
    <>
      <div className="HomeS3">
        <div className="HomeS3_P1">
          <h1 className="HomeS3_P1_H1">our value</h1>
          <p className="HomeS3_P1_P">More than just a company, we stand for…</p>
        </div>
        <div className="HomeS3_P2">
          {/*  */}
          <div className="HomeS3_P2_C">
            {/* Box 1 */}
            <HomeS3P2C Image={HomeS3P2C1L1} H1Text="Passion" PText="Passion drives us to consistently innovate, develop exceptional products, and provide unparalleled customer service, making your success our top priority." />
            {/* Box 2 */}
            <HomeS3P2C Image={HomeS3P2C1L2} H1Text="Customer Focused" PText="We collaborate with our customers to understand their specific needs, and create tailored solutions to improve their professional image and employee satisfaction." />
          </div>
          {/*  */}
          <div className="HomeS3_P2_C">
            {/* Box 3 */}
            <HomeS3P2C Image={HomeS3P2C1L3} H1Text="Excellence" PText="Develop solutions that solve industry challenges. We are always pushing boundaries and investigating new technologies to have an excellent effect." />
            {/* Box 4 */}
            <HomeS3P2C Image={HomeS3P2C1L4} H1Text="INNOVATION" PText="Our team is constantly exploring new technologies to develop uniforms that are not only functional but also comfortable and fashionable." />
          </div>
        </div>
      </div>
    </>
  );
}
//
function HomeS4() {
  return (
    <>
      <div className="HomeS4">
        <div className="HomeS4_P1">
          <h1 className="HomeS4_P1_H1">Explore Our Collections</h1>
          <div className="HomeS4_P2_Mob">
            <img src={HomeS4P2Img1} alt="HomeS4P2Img1" className="HomeS4P2Img" />
            <img src={HomeS4P2Img2} alt="HomeS4P2Img2" className="HomeS4P2Img" />
            <img src={HomeS4P2Img3} alt="HomeS4P2Img3" className="HomeS4P2Img" />
          </div>
          <p className="HomeS4_P1_P">Discover our wide variety of uniforms, carefully designed to meet the needs of schools and institutions. From traditional to modern styles.</p>
          <a href="/Gallery" className="HomeS4_P1_A">
            <button className="HomeS4_P1_B">Explore Our Gallery</button>
          </a>
        </div>
        <div className="HomeS4_P2">
          <img src={HomeS4P2Img1} alt="HomeS4P2Img1" className="HomeS4P2Img" />
          <img src={HomeS4P2Img2} alt="HomeS4P2Img2" className="HomeS4P2Img" />
          <img src={HomeS4P2Img3} alt="HomeS4P2Img3" className="HomeS4P2Img" />
        </div>
      </div>
    </>
  );
}
function HomeS5() {
  return (
    <>
      <div className="HomeS5">
        <div className="HomeS5_P1">
          <h1 className="HomeS5_P1_H1">We Provide various styles for various fields</h1>
        </div>
        <div>
          <HomeS5P2 />
        </div>
      </div>
    </>
  );
}
function HomeS6() {
  return (
    <>
      <div className="HomeS6">
        <div className="HomeS6_P1">
          <img src={HomeS6ImgL} alt="" className="HomeS6P1ImgB" />
          <h5>At Uniform Arena</h5>
          <h1>Tailored to Perfection</h1>
          <div className="HomeS6_P2 HomeS6_P2_Mob">
            <img src={HomeS6ImgR} alt="HomeS6ImgR" className="HomeS6ImgR" />
          </div>
          <p>
            Our team of experienced designers understands the importance of customization. Whether you're looking for specific
            <span> colors</span>, <span>logos</span>, or
            <span> special features</span>, we work closely with you to bring your vision to life. Every detail is meticulously considered to ensure your uniforms are as unique as your school.
          </p>
        </div>
        <div className="HomeS6_P2 HomeS6_P2_Web">
          <img src={HomeS6ImgR} alt="HomeS6ImgR" className="HomeS6ImgR" />
        </div>
      </div>
    </>
  );
}
function HomeS7() {
  return (
    <>
      <div className="HomeS7">
        <div className="HomeS7P1">
          <div className="HomeS7P1Img HomeS7P1Img_Web">
            <img src={HomeS7P1Img} alt="HomeS7P1Img" className="HomeS7P1Image" />
          </div>
        </div>
        <div className="HomeS7P2">
          <img src={HomeS7P2ImgB} alt="" className="HomeS7P2ImgB" />
          <h3>At Uniform Arena </h3>
          <h1>Quality You Can Trust</h1>
          <div className="HomeS7P1Img HomeS7P1Img_Mob">
            <img src={HomeS7P1Img} alt="HomeS7P1Img" className="HomeS7P1Image" />
          </div>
          <p>At Uniform Arena, quality is our top priority. We source only the finest materials and employ rigorous quality control measures to ensure that every garment meets our high standards. From durability to comfort, our uniforms are built to withstand the demands of daily wear.</p>
        </div>
      </div>
    </>
  );
}

function HomeS8() {
  return <>{/* <TestimonialHomeS8 /> */}</>;
}

function HomeS9() {
  return (
    <>
      <div className="HomeS9">
        <h3>Have questions or a unique uniform vision?</h3>
        <h1>Email us today to discuss how we can bring it to life!</h1>
        <a href="/contact-us">
          <ButtonContact />
        </a>
      </div>
    </>
  );
}
