import React from "react";
import "./HomeS5P2.css";
import HomeS5P2Img1 from "../../../Assets/Svg/HomeS5P2Img1.svg";
import HomeS5P2Img2 from "../../../Assets/Svg/HomeS5P2Img2.svg";
import HomeS5P2Img3 from "../../../Assets/Svg/HomeS5P2Img3.svg";
import HomeS5P2Img4 from "../../../Assets/Svg/HomeS5P2Img4.svg";
import HomeS5P2Img5 from "../../../Assets/Svg/HomeS5P2Img5.svg";
import HomeS5P2Img6 from "../../../Assets/Svg/HomeS5P2Img6.svg";
import HomeS5P2Img7 from "../../../Assets/Svg/HomeS5P2Img7.svg";
import HomeS5P2Img8 from "../../../Assets/Svg/HomeS5P2Img8.svg";
import HomeS5P2Img9 from "../../../Assets/Svg/HomeS5P2Img9.svg";
import HomeS5P2Img10 from "../../../Assets/Svg/HomeS5P2Img10.svg";
import HomeS5P2Img11 from "../../../Assets/Svg/HomeS5P2Img11.svg";
import HomeS5P2Img12 from "../../../Assets/Svg/HomeS5P2Img12.svg";
import HomeS5P2Img13 from "../../../Assets/Svg/HomeS5P2Img13.svg";

export default function HomeS5P2() {
  return (
    <>
      <div className="HomeS5Web">
        <div className="HomeS5P2">
          {/* <HomeS5P2C Image={HomeS5P2Img1} H3Text="OFFICE UNIFORMS" /> */}
          <HomeS5P2C Image={HomeS5P2Img2} H3Text="EDUCATIONAL UNIFORMS" />
          <HomeS5P2C Image={HomeS5P2Img3} H3Text="CORPORATE UNIFORMS" />
          <HomeS5P2C Image={HomeS5P2Img4} H3Text="INDUSTRIAL UNIFORMS" />
        </div>
        <div className="HomeS5P2">
          {/* <HomeS5P2C Image={HomeS5P2Img5} H3Text="FACTORY UNIFORMS" /> */}
          {/* <HomeS5P2C Image={HomeS5P2Img6} H3Text="MAINTENANCE UNIFORMS" /> */}
          <HomeS5P2C Image={HomeS5P2Img7} H3Text="HOSPITALITY UNIFORMS" />
          <HomeS5P2C Image={HomeS5P2Img10} H3Text="MEDICAL UNIFORMS" />
          <HomeS5P2C Image={HomeS5P2Img11} H3Text="CASUAL UNIFORMS" />
        </div>
        <div className="HomeS5P2">
          {/* <HomeS5P2C Image={HomeS5P2Img8} H3Text="SECURITY UNIFORMS" /> */}
          {/* <HomeS5P2C Image={HomeS5P2Img9} H3Text="CLEANING UNIFORMS" /> */}
        </div>
        <div className="HomeS5P2">
          <HomeS5P2C Image={HomeS5P2Img12} H3Text="SPORTS WEAR UNIFORMS" />
          {/* <HomeS5P2C Image={HomeS5P2Img13} H3Text="HOTEL STAFF UNIFORM" /> */}
        </div>
      </div>
      {/*  */}
      <div className="HomeS5Mob">
        <div className="HomeS5P2">
          <HomeS5P2C Image={HomeS5P2Img2} H3Text="EDUCATIONAL UNIFORMS" />
          <HomeS5P2C Image={HomeS5P2Img3} H3Text="CORPORATE UNIFORMS" />
        </div>
        <div className="HomeS5P2">
          <HomeS5P2C Image={HomeS5P2Img7} H3Text="HOSPITALITY UNIFORMS" />
          <HomeS5P2C Image={HomeS5P2Img10} H3Text="MEDICAL UNIFORMS" />
        </div>
        <div className="HomeS5P2">
          <HomeS5P2C Image={HomeS5P2Img11} H3Text="CASUAL UNIFORMS" />
          <HomeS5P2C Image={HomeS5P2Img12} H3Text="SPORTS WEAR UNIFORMS" />
        </div>
        <div className="HomeS5P2">
          <HomeS5P2C Image={HomeS5P2Img4} H3Text="INDUSTRIAL UNIFORMS" />
        </div>
      </div>
    </>
  );
}
function HomeS5P2C({ Image, H3Text }) {
  return (
    <>
      <div className="HomeS5P2C">
        <img src={Image} alt="" className="HomeS5P2C_Img" />
        <h3 className="HomeS5P2C_H3">{H3Text}</h3>
      </div>
    </>
  );
}
